<template>
    <div>
        <temp :isEdit="true"></temp>
    </div>
</template>

<script>
    import temp from './temp.vue';
    export default{
        components: {
            temp
        }
    }
</script>